<template>
    <Anonymous>
        <b-overlay class="email-verification-block" :show="showOverlay" rounded="sm">
            <div class="form">
                <div class="row">
                    <div class="col logo">
                        <BigLogo/>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h3 class="logo-subheader">
                            Smart. Simplified. Streamlined.
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <h3 class="login-form-header">
                            A four-digit verification code was sent to {{ $route.params.email }}, please enter it below.
                        </h3>
                        <b-form @submit.prevent="onSubmit" class="mt-4 login-form">
                            <div role="group" class="form-group-input">
                                <b-form-input
                                    id="login-email"
                                    v-model="code"
                                    type="text"
                                    placeholder="Code"
                                    :state="validateState('code')"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-code-feedback">
                                    <div v-if="!$v.code.required">The code is required</div>
                                    <div v-else-if="!$v.code.numeric">Must be numeric</div>
                                    <div v-else-if="!$v.code.minLength">Must be 4 digits</div>
                                    <div v-else-if="!$v.code.serverError">{{ emailVerifyError }}</div>
                                    <div v-else>
                                        This code is not correct
                                    </div>
                                </b-form-invalid-feedback>
                            </div>
                            <div class="mt-4 d-flex">
                                <div class="flex-fill">
                                    <b-button
                                        type="submit"
                                        variant="primary"
                                        :disabled="disabled">Enter >
                                    </b-button>
                                    <router-link v-if="canLoginRedirect" :to="'/login'" class="btn btn-primary ml-2">Login</router-link>
                                </div>
                            </div>
                        </b-form>

                    </div>
                </div>
            </div>
        </b-overlay>

    </Anonymous>
</template>

<script>
import {required, numeric, maxLength, minLength} from "vuelidate/lib/validators";
import Anonymous from "../layouts/Anonymous";
import BigLogo from "../components/BigLogo";
import {validationMixin} from "vuelidate";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "EmailVerify",
    components: {Anonymous, BigLogo,},
    mixins: [validationMixin],
    data() {
        return {
            code: null,
            showOverlay: false
        };
    },
    computed: {
        ...mapGetters('auth', ["emailVerifyError", "redirect", "canLoginRedirect"]),
        disabled: function () {
            return this.$v.$anyError;
        },
        redirectTo() {
            if (this.redirect) {
                return this.redirect;
            }
            if (this.$route.query.redirectTo === undefined)
                return false;

            let result = this.$route.query.redirectTo;

            for (const key in this.$route.query) {
                if (key !== 'redirectTo') {
                    result = result + '&' + key + '=' + this.$route.query[key];
                }
            }
            return result;
        },
    },
    validations: {
        code: {
            required, numeric, minLength: minLength(4), maxLength: maxLength(4),
            serverError: (value, vm) => {
                return vm.emailVerifyError === null;
            }
        }
    },
    methods: {
        ...mapActions('auth', ["emailVerify", "clearRedirect"]),
        ...mapActions('errors', ['clearErrors']),
        onSubmit() {
            this.$v.$touch();
            if (this.$v.$anyError) {
                return;
            }
            this.emailVerify(this.code)
                .then((data) => {
                    if (data === false) {
                        return;
                    }
                    if (this.redirectTo) {
                      this.$router.push(this.redirectTo);
                      this.clearRedirect();
                    } else {
                      this.$router.push('/documents').catch(() => {});
                    }
                });
        },
        validateState(name) {
            if (this.hasErrors) {
                return false;
            }
            const {$dirty, $error} = this.$v[name];
            return $dirty ? !$error : null;
        },
    }
}
</script>

<style scoped>

</style>